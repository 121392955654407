import { makeStyles, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import TabPanel from "../../../components/tabpanel";
import { primaryColor } from "../../../scss/colors.scss";

const useStyles = makeStyles({
  tabRoot: {
    borderBottom: "2px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: primaryColor,
  },
  black: {
    color: "#000000",
  },
  unselect: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  themeFont: {
    fontFamily: ": $font-semi-bold, sans-serif",
  },
  paddingTop: {
    padding: "50px 0px 16px",
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DashboardChartContainer = ({ chartList = [] }) => {
  const classes = useStyles();
  const [selectedChartTab, setSelectedChartTab] = useState(0);
  const handleChangeChartTab = (event, chartIndex) => {
    setSelectedChartTab(chartIndex);
  };

  return (
    <div className="dashboard-chart-group__container">
      <div className="mb-5">
        <Tabs
          value={selectedChartTab}
          onChange={handleChangeChartTab}
          aria-label="simple"
          indicatorColor="secondary"
          textColor="inherit"
          scrollButtons="on"
          variant="scrollable"
          classes={{
            root: classes.tabRoot,
            indicator: classes.indicator,
          }}
        >
          {chartList.map((tab, index) => (
            <Tab
              key={tab.label}
              label={tab.label}
              {...a11yProps(0)}
              classes={{
                textColorInherit:
                  selectedChartTab === index ? classes.black : classes.unselect,
              }}
            />
          ))}
        </Tabs>
      </div>
      {chartList.map((tab, index) => (
        <TabPanel key={tab.label} value={selectedChartTab} index={index}>
          {tab.chart}
        </TabPanel>
      ))}
    </div>
  );
};

DashboardChartContainer.propTypes = {};

export default DashboardChartContainer;
